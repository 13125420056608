<template>
<div>
  <b-card-group deck>
    <b-card
    
      title="All Clients"
    >
    <template>
  <downloadexcel
	class   = "btn btn-default"
	:data   = "items"
	name    = "AllClient.xls">

Export

</downloadexcel>
</template>
<template>
  
  <TableView
  :headers="columns"
  :rows="items"      
  :sort="sort1"
  :filter="filter"
  :filter-included-headers="filterOn"
   @filtered="onFiltered"
>

  <template v-slot:items="{ row }">
    <td>{{ row.id }}</td>
    <td>{{ row.full_name }}</td>
    <td>{{ row.email }}</td>   
    <td>{{ row.child_DOB }}</td>              
    <td>{{ row.registration_Date }}</td>    
    <TablePagination></TablePagination>        
  </template>
  <template v-slot:no-data>
    <span>No data</span>
  </template>
</TableView>
    </template>
    </b-card>  
  </b-card-group>
</div>

</template>

<script>
import downloadexcel from "vue-json-excel";
import TableView from "@/components/TableView.vue"
import TablePagination from "@/components/TablePagination.vue"
export default {
  components:{
    TableView,
    TablePagination,
      downloadexcel,
  },
   data(){
    return{
      columns: [
        {
        key: 'id',
            label: 'ID',
        },
        {
        key: 'name',
        label: 'Full Name',
        },
        {
        key: 'email',
        label: 'Email',
        },
        {
        key: 'child_DOB',
        label: 'Child Date of Birth',
        },
        {
        key: 'reg_date',
        label: 'Registeration Date',
        },
         
    
      ], // column data
      items: [
        {
          id: 1,
          full_name: "Shawna Dubbin",
          email: "sdubbin0@geocities.com",
          child_DOB: "2015-01-20",
          registration_Date: "2015-07-20"
        },
        {
          id: 2,
          full_name: "Odette Demageard",
          email: "odemageard1@spotify.com",
          child_DOB: "2018-11-20",
          registration_Date: "2019-07-20"
        },
        {
          id: 3,
          full_name: "Vera Taleworth",
          email: "vtaleworth2@google.ca",
         child_DOB: "2019-01-04",
          registration_Date: "2020-12-30"
        },
        {
          id: 4,
          full_name: "Lonnie Izkovitz",
          email: "lizkovitz3@youtu.be",
          child_DOB: "2014-01-20",
          registration_Date: "2015-07-20"
        },
        {
          id: 5,
          full_name: "Thatcher Stave",
          email: "tstave4@reference.com",
         child_DOB: "2017-01-20",
          registration_Date: "2018-08-10"
        },
        {
          id: 6,
          full_name: "Karim Chipping",
          email: "kchipping5@scribd.com",
          child_DOB: "2019-01-07",
          registration_Date: "2020-07-20"
        },
        
      ], // tabular data
      sort1:{
        columns: 'full_name',
        order: 'asc'
      },    
      filterByFormatted: true,
      filter: null,
        filterOn: [],
         Page:1,
      pagination:{
        itemsPerPage: 3,
        visualStyle: 'select'
      }
    }    
  },
 
  headers: {
  type: Array,
  default() {
    return []
  },
  required: true
},   
rows: {
  type: Array,
  default() {
    return []
  }, 
  required: true
},        
sort: {
  type: Object,
  default() {
    return {}
  }
},
//  page : Number,
// totalPages: Number,
//  paginationOptions: Object,
pagination: {
  type: Object,
  default() {
    return {}
  }
},
cssStyle: {
  type: String,
  default: 'ozn'
},

  computed: {
     filteredItems() {
      return this.items.filter(c => {
        if(this.filter == '') return true;
        return c.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
      })
    },
  },
 
  
methods: {
       sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
     onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
  
}

}
</script>

<style scoped>
.btn.btn-default {
      color: #1313ea;
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    float: right;
}
</style>